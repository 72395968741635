import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";


@Injectable({ providedIn: "root" })

export class StockItemSupplierInfoService {
  constructor(
    @Inject("BASE_URL") private baseUrl: string,
    private http: HttpClient
  ) {}

  public getSupplierInfosById(stockItemId: string) {
    return this.http.get(
      `api/stockitemsupplierinfo?stockItemId=${stockItemId}`
    );
  }

  public updateInfo(stockItemId: string, supplierId: string, model: any) {
    return this.http.put(
      `api/stockitemsupplierinfo?stockItemId=${stockItemId}&supplierId=${supplierId}`,
      model
    )
  }

  public delete(stockItemId: string, supplierId: string) {
    return this.http.delete(
      `api/stockitemsupplierinfo?stockItemId=${stockItemId}&supplierId=${supplierId}`
    )
  }
}