<div id="stock-item-suppliers"
  class="d-flex-column">
  <div class="d-flex-vert-center d-flex-sp-btw w-full mg-b24">
    <span>Stock Item suppliers</span>
  </div>
  <div class="d-flex-vert-center mg-b16 gap-8">
    <button mat-raised-button color="primary" (click)="deleteSelected()">Remove selected supplier</button>
    <button mat-raised-button color="primary" (click)="addSupplier()">+ Add supplier</button>
    <button mat-raised-button color="primary" (click)="makeDefault()">Make selected default</button>
    <mat-form-field class="po-form-field" *ngIf="showSupplierSelect">
      <mat-label>Supplier:</mat-label>
      <mat-select
        (selectionChange)="selectedSupplierChange($event.value)">
        <mat-option *ngFor="let supp of suppliers"
          [value]="supp">
          {{ supp.supplierName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div id="table-container"
    class="mg-b24">
    <ag-grid-angular style="width: 100%; height: 240px;"
      class="ag-theme-balham"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowSelection]="'single'"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
  <div mat-dialog-actions class="d-flex-vert-center w-full d-flex-end">
    <button mat-raised-button mat-dialog-close>Close</button>
    <button mat-raised-button color="primary" (click)="saveSelected()">Save changes</button>
  </div>
</div>